<template>
  <v-container fluid>
    <div class="from-content">
      <div class="from">
        <v-form ref="form" @submit.prevent="ValidateForm">
          <v-row>
            <v-col cols="12" md="12">
              <label class="label-input">{{
                $t("authorizeDocument.selectUser")
              }}</label>
              <v-autocomplete
                filled
                dense
                item-text="name"
                item-value="user_id"
                outlined
                :items="userCompany"
                hide-selected
                v-model="data.user_id"
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="8">
              <v-btn
                class="btn-save-change"
                type="submit"
                :loading="btnLoading"
              >
                {{ $t("authorizeDocument.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      btnLoading: false,
      userCompany: [],
      data: {
        user_id: "",
      },
    };
  },
  methods: {
    focusNext(e) {
      const inputs = Array.from(e.target.form.querySelectorAll("input"));
      const index = inputs.indexOf(e.target);
      if (index < inputs.length) {
        inputs[index + 1].focus();
      }
    },
    fetchAuthUserCompany() {
      this.$axios.get(`company/list-employee-name`).then((res) => {
        if (res.status === 200) {
          this.userCompany = res.data.data;
        }
      });
    },
    ValidateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
        this.btnLoading = true;
      }
    },

    saveChange() {
      if (this.data.user_id == "") {
        this.btnLoading = false;
        return;
      }
      this.$axios
        .post(`company/add/document/authorized/user`, this.data)
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
            this.$router.push({
              name: "authorize.document.index",
            });
            this.btnLoading = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            const obj = error.response.data.errors;
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
            }
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    this.fetchAuthUserCompany();
  },
};
</script>

<style scoped lang="scss">
.from-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .from {
    width: 800px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.text-date-label {
  color: #6e6d6d;
  font-weight: normal;

  .text-show-error {
    font-size: 12px;
    color: red;
  }
}
</style>
